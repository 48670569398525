import React from 'react';
import logo from './logo_white.svg';
import './App.css';
import Login from './Login';

import Dashboard from './pages/dashboard';
import Domains from './pages/domains';
import Billing from './pages/billing';
import Plan from './pages/plan';
import Subscription from './pages/subscription';

import API from './api';

class App extends React.Component {

  state = {
    login: false,
    sidebar: true,
    page: "dashboard"
  }


  changePage(page){
    this.setState({page});
  }

  renderPage(page){
    switch (page) {
      case "dashboard":
        return <Dashboard/>;
        break;
      case "domains":
        return <Domains/>;
        break;
      case "billing":
        return <Billing/>;
        break;
      case "plan":
        return <Plan/>;
        break;
      case "subscription":
        return <Subscription/>;
        break;
      default:
        return <Dashboard/>;
    }
  }

  toggleSidebar(){
    let sidebar = this.state.sidebar;

    this.setState({
      sidebar: !sidebar
    })
  }

  isLoggedIn(){
    var url = "https://api.svgapi.com/user.php";

    fetch(url, { method: 'GET', credentials: 'include'})
    .then(res => res.json())
    .then((res) => {
      if(res == null){
        console.log("NO_AUTH");
      }
      if(res){
        console.log("Logged In");
        API.setUser(res);
        this.setState({login: true})
      }
    }).catch(err => {
      console.log(err);
    });
  }

  render(){
    if(!this.state.login){
      return <Login loggedIn={() => this.isLoggedIn()}/>
    }

    return (
      <div className="App">
         <div className="dashboardCarrier">
            {this.state.sidebar &&
              <div className="sidebar">
                 <div className="enterance">
                    <img className="logo" src={logo} style={{width: 110}}/>
                    <div className="partner">ADMIN PANEL</div>
                 </div>
                 <div className="nav">
                    <div onClick={() => this.changePage("dashboard")} className={this.state.page == "dashboard" ? "active" : ""}>Dashboard</div>
                    <div onClick={() => this.changePage("domains")} className={this.state.page == "domains" ? "active" : ""}>Manage Domains</div>
                    <div className="seperator"></div>
                    <div onClick={() => this.changePage("billing")} className={this.state.page == "billing" ? "active" : ""}>Billing Info</div>
                    <div onClick={() => this.changePage("plan")} className={this.state.page == "plan" ? "active" : ""}>Change Plan</div>
                    <div onClick={() => this.changePage("subscription")} className={this.state.page == "subscription" ? "active" : ""}>Subscription</div>
                 </div>
                 <div className="navbar-group">
                    <svg onClick={() => this.setState({login: false})} width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
                    <div>Hey, {API.getUser().name} ❤</div>
                 </div>
              </div>
            }
            <div className="dashboardRight" id="dr">
               <div className="dashboardHeader">
                  <svg width="17px" height="10px" viewBox="0 0 17 10" version="1.1"  onClick={() => this.toggleSidebar()} style={{marginRight: 15, cursor: "pointer"}}>
                    <defs/>
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Group-5" fill="#5A5A5A" fillRule="nonzero">
                            <path d="M16,8 L7,8 C6.45,8 6,8.45 6,9 C6,9.55 6.45,10 7,10 L16,10 C16.55,10 17,9.55 17,9 C17,8.45 16.55,8 16,8 Z M16,4 L7,4 C6.45,4 6,4.45 6,5 C6,5.55 6.45,6 7,6 L16,6 C16.55,6 17,5.55 17,5 C17,4.45 16.55,4 16,4 Z M16,0 L7,0 C6.45,0 6,0.45 6,1 C6,1.55 6.45,2 7,2 L16,2 C16.55,2 17,1.55 17,1 C17,0.45 16.55,0 16,0 Z" id="Shape"/>
                            <path d="M2.8825,1.29647719 L0.2925,3.88647719 C-0.0975,4.27647719 -0.0975,4.90647719 0.2925,5.29647719 L2.8825,7.88647719 C3.5125,8.51647719 4.5925,8.06647719 4.5925,7.17647719 L4.5925,1.99647719 C4.5925,1.10647719 3.5125,0.666477188 2.8825,1.29647719 Z" id="Shape"/>
                        </g>
                    </g>
                  </svg>

                  <div className="title"><span>{this.state.page[0].toUpperCase() + this.state.page.substr(1)}</span></div>
                  <div></div>
               </div>
               <div className="dashboardContent">
                  {this.renderPage(this.state.page)}
                  <div style={{height: 150}}></div>
               </div>
            </div>
         </div>
      </div>
    );
  }
}


export default App;
