/*
import makeid from './makeid';
import Event from './event';
import Analytics from 'react-ga';
*/

//const URL = "https://api.usememo.com/";
const DEVELOPMENT = true;

class API {
  constructor(){
    //Analytics.initialize('UA-138987685-1');
    this.development = DEVELOPMENT;
		//this.event = Event;
  }

  getUser(){
    return this.user;
  }

  fetchUser(){
    var url = "https://api.svgapi.com/user.php";

    fetch(url, { method: 'GET', credentials: 'include'})
    .then(res => res.json())
    .then((res) => {
      if(res == null){
        console.log("NO_AUTH");
      }
      if(res){
        console.log("Logged In");
        this.setUser(res);
      }
    }).catch(err => {
      console.log(err);
    });
  }


  setUser(user){
    this.user = user;
  }
}

const _api = new API();
export default _api;
