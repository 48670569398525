import React from 'react';
import logo from './logo_white.svg';
import './App.css';

class App extends React.Component {

  state = {
    email: "",
    pass: ""
  }

  authUser(){
    console.log("start auth");
    var url = "https://api.svgapi.com/login.php";
    var formData = new FormData();
    formData.append('email', this.state.email);
    formData.append('pass', this.state.pass);
    this.setState({indicator: "Please wait..."});

    return fetch(url, { method: 'POST', credentials: 'include', body: formData })
    .then(res => res.json()).then(res => {
      console.log(res);
      if(res != "success"){
        this.setState({indicator: "Please check your login credentials!"});
      }else{
        this.setState({indicator: "Success!"});
        setTimeout(() => {
          this.props.loggedIn();
        }, 200)
      }
    });

  }

  onFormSubmit = e => {
    this.authUser();
    e.preventDefault();
  }

  fetchUser(){
  }

  render(){
    return (
      <div className="App">
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <div className="panelTop"><img src={logo} style={{width: 150}}/>
              <div className="partner">Control Panel</div>
          </div>
          {this.state.indicator &&
            <div className="indicator">{this.state.indicator}</div>
          }
          <form onSubmit={this.onFormSubmit}>
            <p>
                <label>
                    <div className="label">E-mail:</div>
                    <input type="text" onChange={(e) => this.setState({email: e.target.value})}/>
                </label>
            </p>
            <p>
                <label>
                    <div className="label">Password:</div>
                    <input type="password" onChange={(e) => this.setState({pass: e.target.value})}/>
                </label>
            </p>
            <div className="button" style={{width: 300, textAlign: "center"}} onClick={() => this.authUser()}>Login</div>
            <button type="submit" style={{display: "none"}}>Submit</button>
          </form>
          <p className="registerExplain">If you haven't already; in order to register, please go to <a href="https://svgapi.com/pricing">Pricing</a> and choose a plan suitable for you. </p>
          <p className="registerExplain"><br/>Contact us at <a href="mailto:info@svgapi.com">info@svgapi.com</a> for your questions and problems. </p>
      </div>
  </div>
    );
  }
}


export default App;
