import React from 'react';
import API from '../api';

class App extends React.Component {

  getPlanCredit(plan){
    if(plan == "starter"){
      return 5000;
    }else if(plan == "maker"){
      return 50000;
    }else if(plan == "professional"){
      return 500000;
    }
  }

  render(){
    return (
      <div>

        <h2>Usage</h2>
        <p>See your usage for your current plan.</p>
        <div style={{width: 300, background: "#eee", boxShadow: "0 0 5px rgba(0, 0, 0, .05)"}}>
          <div style={{width: Number(this.getPlanCredit(API.getUser().plan) - API.getUser().credit) / this.getPlanCredit(API.getUser().plan) * 100 + "%", background: "#141414", height: 30}}></div>
        </div>
        <p style={{paddingTop: 20, fontSize: "1.2em"}}>{(this.getPlanCredit(API.getUser().plan) - API.getUser().credit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {this.getPlanCredit(API.getUser().plan).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} API Calls</p>

        <h2>Announcements</h2>
        <p>Updates from us, version updates, breaking updates and or the news we would like you to know will be listed here.</p>
        <p className="nonExisting">There is no announcements to show</p>


      </div>
    );
  }
}


export default App;
