import React from 'react';
import API from '../api';

class App extends React.Component {

  getPlanId(plan){
    if(plan == "starter"){
      return 580889;
    }else if(plan == "maker"){
      return 580882;
    }else if(plan == "professional"){
      return 580884;
    }
  }

  componentDidMount(){
    window.Paddle.Setup({
      vendor: 106664,
      eventCallback: (data) => {
        console.log("paddle is working", data.event);
        if(data.event == "Checkout.Complete"){
          API.fetchUser();
        }
      }
    });
  }

  getPrice(plan){
    if(plan == "starter" || plan == "free"){
      return "9,90";
    }else if(plan == "maker"){
      return "24,90";
    }else if(plan == "professional"){
      return "149,90";
    }
  }

  test(){
    window.Paddle.Checkout.open({
      product: 580889,
      email: "tokakburak@gmail.com"
    });
  }

  changePlan(plan){
    let subscription_id = JSON.parse(API.getUser().subscription_object).subscription_id;
    let planid = this.getPlanId(plan);


    var url = "https://api.svgapi.com/updatePlan.php?subscription_id="+subscription_id+"&planid="+planid;
    fetch(url, { method: 'GET', credentials: 'include'})
    .then(res => res.json())
    .then((res) => {
      if(res.success == true){
        alert("Successfully done! Please login again for the changes to take effect.");
        window.location.href = "https://panel.svgapi.com";
      }else{
        alert("Error! " + res.error.message);
      }
    });
  }


  render(){
    return (
      <div>
        <h2>Changing Your Plan</h2>
        <p>When you upgrade or change your plan, the difference between plans will be prorated, this means; if you upgrade to another plan, your current plan's not-yet-used value will be reducted from your upgraded bill.</p>

        <div className="plans">
          <p>
            <b>Starter Plan</b>
            <li>5,000 Calls/Month</li>
            <li>Unlimited domains</li>
            <li>$9.90/mo</li>
            <>
              {API.getUser().plan == "starter" && <div className="button" style={{float: "none", background: "#fff", color: "#aaa" }}>Current</div>}
              {API.getUser().plan != "starter" && <div className="button" style={{float: "none"}} onClick={() => this.changePlan("starter")}>Change</div>}
            </>
          </p>
          <p>
            <b>Maker Plan</b>
            <li>50,000 Calls/Month</li>
            <li>Unlimited domains</li>
            <li>$24.90/mo</li>
            <>
              {API.getUser().plan == "maker" && <div className="button" style={{float: "none", background: "#fff", color: "#aaa" }}>Current</div>}
              {API.getUser().plan != "maker" && <div className="button" style={{float: "none"}} onClick={() => this.changePlan("maker")}>Change</div>}
            </>
          </p>
          <p>
            <b>Professional Plan</b>
            <li>500,000 Calls/Month</li>
            <li>Unlimited domains</li>
            <li>$149.90/mo</li>
            <>
              {API.getUser().plan == "professional" && <div className="button" style={{float: "none", background: "#fff", color: "#aaa" }}>Current</div>}
              {API.getUser().plan != "professional" && <div className="button" style={{float: "none"}} onClick={() => this.changePlan("professional")}>Change</div>}
            </>

          </p>
        </div>
        <p>Contact us if you are maintaining a bigger volume project, with your estimated monthly call volume and information about your project at <a href="mailto:info@svgapi.com" target="_blank">info@svgapi.com</a></p>

      </div>
    );
  }
}


export default App;
