import React from 'react';
import API from '../api';

import "./page.css";

class App extends React.Component {

  state = {
    domain: API.getUser().domain,
    added: false,
    development: API.getUser().is_dev != 0
  }

  toggleDevelopment(){
    let dev = this.state.development;
    dev = !dev;
    console.log(dev);
    this.setState({development: dev});

    var url = "https://api.svgapi.com/updateDev.php?dev="+Number(dev);
    fetch(url, { method: 'GET', credentials: 'include'})
    .then(res => res.json())
    .then((res) => {
      API.user.is_dev = dev ? 1 : 0;
    });
  }

  updateDomain(domain){
    console.log(domain);
    var url = "https://api.svgapi.com/update.php?domain="+domain;

    fetch(url, { method: 'GET', credentials: 'include'})
    .then(res => res.json())
    .then((res) => {
      API.user.domain = domain;
      //this.event.emit("login", false);
    });
  }

  addNew(){
    let dom = this.state.domain;

    if(dom){
      dom = dom + "," +this.refs._domain.value;
    }else{
      dom = this.refs._domain.value;
    }

    this.setState({domain: dom, added: true})
    this.updateDomain(dom);
  }

  removeDomain(domain){
    let dom = this.state.domain;
    dom = dom.replace(domain + ",", "");
    dom = dom.replace(domain, "");

    this.setState({domain: dom});
    this.updateDomain(dom);
  }

  render(){
    return (
      <div>

        <h2>Allowed Domains</h2>
        <p>See the allowed domains for your account spesific endpoint.</p>

        <div className="domains">
          {!this.state.domain &&
            <div className="nonExisting">You don't have any allowed domains, you can add one in the following section.</div>
          }
          {this.state.domain && this.state.domain.split(",").map((domain, i) => {
            if(domain){
              return (<p key={i}>{domain} <span className="remove" onClick={() => this.removeDomain(domain)}>(Remove)</span></p>)
            }
          })}
        </div>

        <br/>
        <h2>Add Domain</h2>
        <p>Allow another domain to access your account spesific endpoint</p>

        <div className="addNewDomain">
        {!this.state.added &&
          <>
            <input type="text" placeholder={"mydomain.com"} ref={"_domain"}/>
            <div className="button" onClick={() => this.addNew()}>Allow Domain</div>
          </>
        }
        </div>

        <br/>
        <h2>Domain Key</h2>
        <p>See your domain key, used for the API endpoint.</p>
        <div className="code">{API.getUser().domain_key}</div>
        <p style={{position: "relative", top: 8}}>Using it in an example.</p>
        <div className="code"><b>GET</b> https://api.svgapi.com/v1/<b>{API.getUser().domain_key}</b>/list/?search=apple</div>

        <br/>
        <h2>Development Mode</h2>
        <p>You can toggle development mode to allow all requests for your api endpoint. In order to use API in backend or for your localhost, you should toggle this.</p>

        <label className="switch">
          <input type="checkbox" checked={this.state.development} readOnly={true}/>
          <span className="slider round"></span>
        </label>
        <div style={{width: 50, height: 20, position:"relative", bottom: 40, background: "transparent", cursor: "pointer"}} onClick={() => this.toggleDevelopment()}></div>

        <p><b>NOTE:</b> If you are making frontend calls to your api endpoint, make sure to <b>not keep development mode open</b>, as others access to your endpoint can use your credits, from other domains or via backend.</p>

      </div>
    );
  }
}


export default App;
