import React from 'react';
import API from '../api';

class App extends React.Component {

  state = {
    bills: null
  }

  componentDidMount(){
    this.fetchBills();
  }

  fetchBills(){
    var url = "https://api.svgapi.com/bill.php";

    fetch(url, { method: 'GET', credentials: 'include'})
    .then(res => res.json())
    .then((res) => {
      this.setState({bills: res});
    }).catch(err => {
      console.log(err);
    });
  }

  render(){
    let paymentObject = JSON.parse(API.getUser().payment_object);

    return (
      <div>
         <h2>Billing Info</h2>
         <p>All the invoices are sent to your email address ({API.getUser().email}) the same day your card/paypal is charged. By clicking edit this invoice, you can add your business address.</p>
         <p>Your next bill will be at <strong>{API.getUser().next_bill_date}</strong> with an amount of <strong>{paymentObject.next_payment_amount} USD</strong></p>

         <h2>Past Invoices</h2>
         <p>See and edit your past invoices, a copy of this invoices are also sent to your email address. The invoices opens in new tab, and you can edit your business address.</p>

         {this.state.bills && this.state.bills.map((bill, i) => {
           let billObj = JSON.parse(bill);
           return (
             <div className="bill" key={i}>
              Order Id: {billObj.order_id}<br/>
              Costumer: {billObj.customer_name}<br/>
              Payment Time: {billObj.event_time}<br/>
              Payment Method: {billObj.payment_method}<br/>
              Payment Amount: {billObj.balance_gross} USD<br/>
              Plan Name: {billObj.plan_name}<br/>
              <a href={billObj.receipt_url} target="_blank">See Invoice</a>
            </div>
           )
         })}

         <p>Do you see a problem? Contact us and we will try to fix it at <a href="mailto:info@svgapi.com" target="_blank">info@svgapi.com</a></p>

      </div>
    );
  }
}


export default App;
