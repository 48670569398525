import React from 'react';
import API from '../api';

class App extends React.Component {

  getPrice(plan){
    if(plan == "starter" || plan == "free"){
      return "9,90";
    }else if(plan == "maker"){
      return "24,90";
    }else if(plan == "professional"){
      return "149,90";
    }
  }

  render(){
    let subObj = JSON.parse(API.getUser().subscription_object);
    return (
      <div>
         <h2>Subscription Details</h2>
          {subObj.status != "deleted" &&
            <>
              <p>You are currently subscribed to <strong>{API.getUser().plan}</strong> plan. Which is ${this.getPrice(API.getUser().plan)}/month, your card will be charged by paddle.com when applicable.</p>
              <p>Your next bill date is <strong>{API.getUser().next_bill_date}</strong> (this information is supplied by paddle.com).</p>
            </>
          }

          {subObj.status == "trialing" &&
            <div><p>You are currently in trial period, your period ends in the <strong>{subObj.next_bill_date}</strong>. Don't worry, if you forget to cancel your subscription, you can contact us to get a full refund!</p></div>
          }

          <h2>Update Payment Method</h2>
          <p>If you would like to change your payment method, or change your current card, click the following link.</p>
          <p><a href={subObj.update_url} target="_blank">Click to Change</a> (Opens in a new tab)</p>


          <h2>Canceling Subscription</h2>
          <p>You can cancel your subscription anytime for any reason, all your data in our server will be removed 7 days after your current paid period ends. During this time, you can always come back and choose another plan on "Change Plan" tab.</p>
          <p><a href={subObj.cancel_url} target="_blank" style={{color: "red"}}>Click to Cancel</a> (Opens in a new tab)</p>
          <p><b>Note:</b> After you cancel your subscription, make sure you getan information email from paddle.</p>
          <p>Your current subscription status is: <b>{subObj.status}</b> (You might need to relogin after canceling if you think this is not accurate)</p>
      </div>
    );
  }
}


export default App;
